table {
  width: 100%;
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  text-align: left;
  padding: 20px;
}
tr button { width: 100%; margin: 5px auto; }

.empfehlungen {
  position: relative;
}
.empfehlungen > form {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.empfehlungen > form > .inputWrapper {
  flex: 1 1 100%;
}

.empfehlung-step-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc((100% - 20px - 30px) / 2);
  padding: 10px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  outline-offset: -5px;
}
.empfehlung-step-marker.locked {
  background: white;
  outline: 5px solid var(--text-color);
}
.empfehlung-step-marker.unlocked {
  background: var(--color-primary);
  color: white;
}
.empfehlung-step-marker.border {
  background: white;
  outline: 5px solid var(--color-primary);
}
.empfehlung-step-marker > h3 {
  color: inherit;
  padding: 0;
  margin: 0;
}

.empfehlungen > form:nth-child(n + 2) > .empfehlung-step-marker::before {
  content: ' ';
  position: absolute;
  bottom: 50%;
  height: 100%;
  width: 5px;
  z-index: -1;
}
.empfehlungen > form:nth-child(n + 2) > .empfehlung-step-marker.locked::before {
  background: var(--text-color);
  filter: opacity(0.5);
}
.empfehlungen > form:nth-child(n + 2) > .empfehlung-step-marker.border::before,
.empfehlungen > form:nth-child(n + 2) > .empfehlung-step-marker.unlocked::before {
  background: var(--color-primary);
}
