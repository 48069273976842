html {
  --color-primary: #6e7a58;
  --color-secondary: var(--color-primary);
  --font-family: Roboto;
  --text-color: #7a7a7a;
  --font-weight-primary: 300;
  --font-weight-secondary: 400;

  --header-height: 122px;
  --footer-height: 630px;
  --shadow: 6px 6px 9px rgba(0, 0, 0, 0.2);

  font-family: var(--font-family), Roboto, sans-serif;
  font-weight: var(--font-weight-primary);
  color: var(--text-color);
}
@media (max-width: 921px) {
  html {
    font-size: 91.2%;
  }
}

body,
input,
select,
button {
  font-family: var(--font-family), "Roboto", Roboto, sans-serif;
}

header,
footer {
  background-color: var(--color-primary);
  color: white;
  font-size: 1rem;
  line-height: 1.6em;
  box-shadow: var(--shadow);
  padding: 0 calc((100% - 1140px) / 2);
}

header {
  top: 0;
  margin: 0;
  position: sticky;
  height: var(--header-height);
  z-index: 1;
}
@media (max-width: 767px) {
  html {
    --header-height: 81px;
  }
}
.content {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

header img,
header svg {
  margin: 10px;
  padding: 0;
  max-height: calc(var(--header-height) - 20px);
}

header a,
footer a {
  text-decoration: none;
  color: inherit;
}

section {
  max-width: 1140px;
  margin-top: 100px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
}
@media (max-width: 767px) {
  section {
    margin-top: 57px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}

section h1,
section p {
  margin: 10px;
}

h5 {
  font-size: 27px;
  font-weight: 700;
  text-transform: uppercase;
}

h2,
h3 {
  color: var(--color-secondary);
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.25em;
  text-align: center;
  vertical-align: baseline;
  padding: 10px 10px 5px 10px;
  margin: 0px;
  margin-bottom: 20px;
}

footer > .flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 70px;
  padding-bottom: 70px;
  margin: auto;
  margin-top: 73px;
}

footer p {
  margin: 0;
}

footer .contact-info,
footer .hours {
  padding: 10px;
  flex-basis: initial;
  min-width: 300px;
  width: calc(50% - 40px);
  box-sizing: border-box;
}

footer img,
footer svg {
  width: 60%;
}

address {
  font-style: normal;
}

address ul {
  padding: 0;
}

.hours {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.hours h5,
.hours strong {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 20px 0;
}

.links {
  margin: auto;
  width: fit-content;
  padding: 10px;
  list-style: none;
}
.links ul {
  list-style: none;
  padding: 0;
  display: flex;
}
.links li {
  padding: 0 20px;
}
.links li:nth-child(n + 2) {
  border-left: dotted;
}

.inputWrapper {
  margin: 35px 10px 45px 10px;
  font-size: 15px;
}

.inputWrapper > * {
  margin: 5px 0;
}

.rangeWrapper {
  background-color: #eee;
  margin: 0;
  padding: 0;
  height: 30px;
}

.range .datalist {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.6em;
  font-size: 1rem;
  font-weight: var(--font-weight-secondary);
}

.range .datalist .option {
  flex: 1 1 100%;
  text-align: center;
  background: transparent;
  color: inherit;
}
.range .datalist .option:disabled {
  outline: none;
  box-shadow: none;
}

.range .datalist .option:not([disabled]):focus,
.range .datalist .option:not([disabled]):hover {
  background-color: var(--color-primary);
  color: white;
}

form.form-checkbox {
  margin-left: auto;
  margin-right: auto;
  max-width: 972px;
}

label {
  font-size: 1rem;
  font-weight: var(--font-weight-secondary);
}

/* Hide default range styles */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
}
/*
input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
}
*/
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  /* width, height, and border seem to be necessary in chrome */
  width: 10px;
  height: 30px;
  border: 1px solid transparent;
}
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
}

/* Style range */
input[type="range"] {
  width: 100%;
  height: 30px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  background-color: transparent;
  background-image: linear-gradient(var(--color-primary), var(--color-primary));
  background-repeat: no-repeat;
  transition: background-size 0.3s;
}

.checkbox-normal {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  position: relative;
}
.checkbox-normal input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-normal .check {
  border-radius: 4px;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--color-primary);
  color: white;
}
.checkbox-normal input:hover + .check,
.checkbox-normal input:focus + .check {
  outline: 2px solid var(--color-primary);
}

.checkbox {
  display: inline-block;
  border-radius: 23px 23px 23px 23px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  height: 188px;
  padding: 0;
  overflow: hidden;
  text-align: center;
  --margin: 15px;
  color: var(--color-primary);
  margin: var(--margin);
  width: calc(100% - 2 * var(--margin));
  font-weight: 500;
}

@media (min-width: 768px) {
  .checkbox {
    width: calc(33.33% - 2 * var(--margin));
    aspect-ratio: 1;
  }
  h2 {
    font-size: 28px;
  }
}

.checkbox svg {
  display: block;
  width: 87px;
  max-height: 87px;
  margin: auto;
}

.checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}
input[type="checkbox"] + div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.checkbox > div {
  padding-right: 20px;
  padding-left: 20px;
}

input[type="date"]:hover,
input[type="date"]:focus,
input[type="text"]:hover,
input[type="text"]:focus,
select:hover,
select:focus {
  outline: 2px solid var(--color-primary);
}

label.checkbox:has(input[type="checkbox"]:focus) {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
}
label.checkbox:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
}

input[type="checkbox"]:checked + div {
  background-color: var(--color-primary);
  color: white;
}

.screenTime {
  margin: 37px auto;
  display: flex;
  flex-wrap: wrap;
}

.screenTime > div {
  margin-top: 10px;
  min-width: 384px;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input[type="date"],
input[type="text"],
select,
.screenTimeNumber {
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--color-primary);
  width: fit-content;
  margin: 10px auto;
  padding: 12px 24px;
  background: transparent;
}
.screenTimeNumber {
  border: 1px solid var(--color-primary);
  border-radius: 8px;
}

.inputWrapper.text,
.inputWrapper.select {
  display: flex;
  gap: 20px;
  font-size: 18px;
  align-items: center;
  max-width: 740px;
  margin: auto;
}
@media (max-width: 767px) {
  .inputWrapper.text,
  .inputWrapper.select {
    flex-direction: column;
    gap: 0px;
    margin-top: 10px;
  }
  .inputWrapper.text > *,
  .inputWrapper.select > * {
    margin-top: 0;
    padding-top: 0;
    font-size: 1.2rem;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .inputWrapper.text > *,
  .inputWrapper.select > * {
    flex: 1 1 100%;
  }
}

section.bottom-buttons {
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
@media (max-width: 740px) {
  section.bottom-buttons {
    margin: 20px 10px;
    flex-direction: column;
    gap: 20px;
  }
  section.bottom-buttons > a > button {
    width: 100%;
  }
}

button {
  background-color: var(--color-primary);
  font-size: 18px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  transition: box-shadow 0.2s;
}

section a {
  text-decoration: none;
}

button:focus,
button:hover {
  cursor: pointer;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.5);
}
a:focus,
button:focus {
  outline: 0;
  border: 0;
}

button:disabled {
  cursor: not-allowed;
  background-color: white;
  outline: 2px solid var(--text-color);
  color: var(--text-color);
}

label.disabled,
input:disabled,
select:disabled {
  cursor: not-allowed;
}

.loading {
  width: fit-content;
  margin: 50px auto;
  min-height: 90vh;
}

address ul {
  list-style: none;
}
address svg {
  max-width: 16px;
  margin-right: 10px;
}

footer > .version {
  text-align: center;
}

.multipleSelectPopup {
  outline: 2px solid var(--color-primary);
  border-radius: 10px;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  padding: 10px;

  inset: 0;
  margin-left: auto;

  background: white;
}
.multipleSelectPopup > form > div > label {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;
}

.multipleSelectPopup > form > div > label:hover,
.multipleSelectPopup > form > div > label:focus {
  outline: 2px solid var(--color-primary);
}
